import React, { Component } from 'react'
import Landing from '../../../../../components/case-studies/Landing'
import AboutProject from '../../../../../components/case-studies/AboutProject'
import PagesShowcase from '../../../../../components/case-studies/PagesShowcase'
import ProjectShortDescription from '../../../../../components/case-studies/ProjectShortDescription'
import ColorShowcase from '../../../../../components/case-studies/ColorShowcase'
import FontShowcase from '../../../../../components/case-studies/FontShowcase'
import Footer from '../../../../../components/case-studies/Footer'
import Img from 'gatsby-image'
import Layout from '../../../../../components/layout'
import { graphql } from 'gatsby'
import Reveal from '../../../../../components/reveal'
import ProjectImageLightBox from '../../../../../components/case-studies/ProjectImageLightBox'

class Letenky extends Component {
  render() {
    const { data } = this.props
    const { banner_url, screens } = data.project.childMarkdownRemark.frontmatter

    return (
      <Layout showBackButton={true}>
        <Landing
          title={'Tvorba portálu Letenky za babku'}
          description={"Prípadová štúdia"}
          description2={'Pre obľúbený cestovateľský portál s ponukou lacných leteniek sme spracovali kompletný redizajn webu a nový branding.'}
          text={'Pri tomto projekte sme išli úplne od základov. Nové logo, vizuálna identita a branding, UX analýza, webový design a programovanie webstránky na mieru. Všetko toto dokopy muselo fungovať ako celok.'}
          img={data.landingImage}
          what_we_done={[
            { text: 'ux & web design' },
            { text: 'kódovanie front-endu (bootstrap)' },
            { text: 'programovanie (php + javascript)' },
            { text: 'logo & branding', link: '/nase-prace/grafika/logo-branding/letenky-za-babku-branding' },
          ]} />

        <AboutProject
          title={'Profesionálny web nevznikne sám od seba'}
          text={'Tvorba webu založenom na novej brand identite si vyžaduje profesionálny prístup.'}
          list={[
            {
              title: 'Uchopenie projektu',
              text: 'Od klienta sme dostali zadanie s cieľom vytvoriť moderný web na mieru a intuitívny UX dizajn. Zároveň sme navrhli <a class="blue-link" href="/tvorba-loga">nové logo</a> a brand, ktorý mal byť ľahko zapamätateľný a dôveryhodný.Najskôr sme si s klientom prešli všetky požiadavky, aby sme <b>pochopili jeho očakávania</b> a spracovali projektovú dokumentáciu, ktorá by ich reálne naplnila.',
            },
            {
              title: 'Návrh riešenia',
              text: 'Cieľ bol vytvoriť portál, ktorý budú používatelia vyhľadávať vždy, keď sa niekam rozhodnú vycestovať za výhodné peniaze. Kľúčovým bolo vytvoriť prehľadný <a class="blue-link" href="/webdizajn">webdizajn</a>, v ktorom okamžite nájdu čo práve hľadajú a najlepšia technologická úroveň <b>funkcií, rýchlosti a SEO</b>. V rámci úzkej spolupráce sme s klientom diskutovali, ako tieto plány zrealizovať.',
            },
            {
              title: 'Priebeh vývoja',
              text: 'Dizajnér mal voľnú ruku, popustil uzdu kreativite a vytvoril originálny štýl/moodboard. Ďalej sa navrhli varianty loga a celá <a class="blue-link" href="/branding">korporátna identita</a>. Po schválení sme sa pustili do wireframov (rozloženia stránok) a finálneho webdizajnu. Pravidelne sme klientovi prezentovali výstupy <b>a diskutovali o nich</b>. Projekt sme finálne zverili do rúk našich programátorov.',
            },
            {
              title: 'Úspešný výsledok',
              text: 'Pred samotným spustením web optimalizujeme pre prehliadače, responzívnosť a SEO, pre čo najlepšie pozície na Googli. Odovzdaním webu spolupráca s klientom nekončí a projekt vylepšujeme ďalej. Sledujeme správanie návštevníkov cez Hotjar a Google Analytics a na základe získaných dát klientom radíme <b>ako zvýšiť ich tržby.</b>',
            }
          ]}
        />

        <ProjectShortDescription
          title={'Tvorba brandu'}
          description={'Predpokladom plnohodnotnej firemnej identity je pochopenie projektu a jeho zámeru. Návrh loga prichádza potom.'}
          text={'Po konzultáciách s klientom sme pripravili zrozumiteľné firemné princípy, ktoré by mal projekt prezentovať. Následne sme odkomunikovali základné piliere toho, ako sa <b>odlíšiť od konkurencie</b> a cez nový branding značky ukázať jedinečnú esenciu firmy.'} />

        <Reveal>
          <div className={'container p-0 mb-4'}>
            <Img alt={this.constructor.name} fluid={data.logos.childImageSharp.fluid} />
          </div>
        </Reveal>

        <ColorShowcase colors={[
          { code: '#faa634', tintColorCode: '#fbb85d', nameColor: '#FFFFFF', name: 'Tmavý šafrán' },
          { code: '#ed165a', tintColorCode: '#f1457b', nameColor: '#FFFFFF', name: 'Rubínová' },
          { code: '#312b45', tintColorCode: '#5a556a', nameColor: '#FFFFFF', name: 'Payne-ová šedá' }
        ]} />

        <FontShowcase
          fontName={'Foco Bold Italic'}
          text={'Bol vytvorený v 1991 v Londýne a spadá pod Dalton Maag Ltd., ktorí vytvárajú fonty pre typografický dizajn, web fonty, a to jednak pre korporátnych klientov ale aj brandingové agentúry. Svoj skill obsluhovať aj globálnych klientov dokázali aj tým, že font podporuje vyše 50 jazykov, využívaním latinky, prispôsobenej o prídavky pre dané jazyky v štandarde OpenType. Celá séria písiem je navrhovaná na mieru, tvoriac exkluzívnu typografickú rodinu.'}
          isFullWidth={false}
          fontExampleSvg={'/images/svg/fonts/foco-bold-italic.svg'}
        />

        <Reveal>
          <div className={'container p-0 mt-5 mb-5'}>
            <Img className={'mb-5'} alt={this.constructor.name} fluid={data.corporateIdentity.childImageSharp.fluid} />
          </div>
        </Reveal>

        <PagesShowcase
          fixedTitleWidth={true}
          title={'Webový dizajn na mieru'}
          description={'Profesionálny portál sa bez neho nezaobíde.'}
          text={'Letenky za babku je stelesnením jednoduchého, no sofistikovaného a nadčasového portálu. Návštevníci sa v ňom dokážu intuitívne orientovať, čomu napomáha kvalitný UX dizajn webu. Aj vďaka tomu ho pravidelne navštevujú všetci, ktorí hľadajú výhodné letenky a ubytovania.'}
          projectDetail={'/nase-prace/webstranky/portaly/letenky-za-babku'}
          pages={[
            {
              name: 'Hlavná stránka',
              image: data.pageOne,
            },
            {
              name: 'Detail destinácie',
              image: data.pageTwo,
            },
            {
              name: 'Letiská',
              image: data.pageThree,
            },
            {
              name: 'Akcia',
              image: data.pageFour,
            },
            {
              name: 'Magazín',
              image: data.pageFive,
            },
            {
              name: 'Blog',
              image: data.pageSix,
            },
            {
              name: 'Detail blogu',
              image: data.pageSeven,
            },
          ]} />
        {/* All images */}
        <Reveal>
          <div className='container mt-5 mb-5 pt-5 pb-5 casestudy-lightbox'>
            <ProjectImageLightBox bannerUrl={banner_url} screens={screens} title="letenky" />
          </div>
        </Reveal>


        <div className={'dark-section wrapper'}>
          <Reveal>
            <div className={'container'}>
              <div className={'row'}>
                <div className={'col-md-6'}>
                  <div>
                    <h2 className={'cs-title'}>Mobilná verzia</h2>
                    <p className="description">Väčšina návštevníkov webstránky prichádza cez mobily. Myslíme na to.</p>
                    <p className="description-smaller">Vytvoriť responzívny web do posledného detailu je kľúčové, pretože určuje, či bude <a href='/ux-ui-dizajn' class='red-link'>UX (user experience)</a> pre návštevníkov jednoducho použiteľné. Pri projektoch vždy dbáme, aby boli prehľadné a intuitívne na smartfónoch (mobile first), tabletoch a ďalších rozlíšeniach. <br /><br />Aj vďaka tomu sa zvyšuje šanca, že sa z návštevníkov stanú zákazníci, využijú služby, zakúpia produkt či vykonajú inú požadovanú akciu bez akýchkoľvek prekážok. Toto by mala byť priorita každého, kto má vlastný portál a chce, aby mu prinášal čo najväčší zisk.</p>
                  </div>
                </div>
              </div>
              <div className={'row'}>
                <div className={'col-md-12'}>
                  <Img alt={this.constructor.name} fluid={data.screen.childImageSharp.fluid} />
                </div>
              </div>
            </div>
          </Reveal>
        </div>

        {/*<ClientReference
          text={'Nara.design sme kontaktovali na redizajn nášho portálu hypoteka.sk ktorý už bol niekoľko rokov zastaralý. Po niekoľkých stretnutiach prišli dizajnéry s desiatkami vylepšení a skrášlení, ktoré sme ako jeden z najnavštevovanejších portálov potrebovali.'}
          clientName={'David Kopecký'}
          clientRole={'Majiteľ Allriskslovakia'}
          clientPhoto={data.clientPhoto}
        />*/}

        <div className="extern-page-link">
          <a rel="noreferrer" href="https://www.letenkyzababku.sk" target='_blank' className='link-secondary'>www.letenkyzababku.sk</a>
        </div>

        <Footer
          title={'OurServices.Process'}
          subtitle={'OurServices.ProcessSubTitle'}
          link={'/o-nas/ako-pracujeme'}
          leftTopTitle='project'
          nextProjectName={'Lokall'}
          nextProjectUrl={'/nase-prace/pripadove-studie/webstranky/portaly/lokall'}
        />
      </Layout>
    )
  }
}

export const query = graphql`{
  project:  file(relativePath: {eq: "posts/case-studies/letenky_za_babku.md"}) {
        childMarkdownRemark {
          frontmatter {
            banner_url
              screens {
                  href {
                      childImageSharp {
                          fluid(quality: 100, maxWidth: 1920) {
                              ...GatsbyImageSharpFluid_withWebp
                              presentationWidth
                          }
                      }
                  }
                  alt
              }
          }
           
        }
    }
    landingImage: file(relativePath: { eq: "case-studies/LetenkyZaBabku_landing.png" }) {
        childImageSharp {
            fluid( maxWidth: 1600) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    clientPhoto: file(relativePath: { eq: "testimonial_person.png" }) {
        childImageSharp {
            fixed( width: 250) {
                ...GatsbyImageSharpFixed_withWebp
            }
        }
    }
    pageOne: file(relativePath: { eq: "case-studies/lzb_dktp_homepage.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageTwo: file(relativePath: { eq: "case-studies/lzb_dktp_destinacia.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageThree: file(relativePath: { eq: "case-studies/lzb_dktp_airports.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFour: file(relativePath: { eq: "case-studies/lzb_ticketlzb_dktp_ticket.jpg" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageFive: file(relativePath: { eq: "case-studies/lzb_dktp_magazine.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageSix: file(relativePath: { eq: "case-studies/lzb_dktp_blog.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    pageSeven: file(relativePath: { eq: "case-studies/lzb_dktp_blog-detail.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    logos: file(relativePath: { eq: "case-studies/LetenkyZaBabku_logos.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    corporateIdentity: file(relativePath: { eq: "case-studies/LetenkyZaBabku_corporate_identity.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
    screen: file(relativePath: { eq: "case-studies/LetenkyZaBabku_pages.png" }) {
        childImageSharp {
            fluid( maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
}
`

export default Letenky
